import React, { useState } from "react"
import { graphql } from "gatsby"
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap"
import axios from "axios"
import queryString from "query-string"

import Link from "../UI/Link"
import {
  sectionClass,
  successSectionClass,
  rowClass,
  formControlClass,
  successContainerClass,
  successMsgClass,
  sendBtnClass,
  checkboxGroupClass,
  checkboxContainerClass,
  checkmarkClass
} from "./ContactForm.module.scss"

export const fragment = graphql`
  fragment contactFormFragment on WpPage_Flexlayouts_FlexibleLayouts_ContactFormText {
    conditionsLink {
      ... on WpPage {
        link
      }
    }
    privacyLink {
      ... on WpPage {
        link
      }
    }
    submitMessage
    emailTo
  }
`

const ContactForm = ({ conditionsLink, privacyLink, submitMessage, emailTo }) => {
  const [isSubmitting, setIsSubmitting] = useState()
  const [status, setStatus] = useState({ initial: true })
  const [submitDisabled, setSubmitDisabled] = useState(true)

  let mailTo = emailTo || process.env.ORG_EMAIL

  const validate = event => {
    const form = event.currentTarget

    if (form.checkValidity() === false) {
      event.stopPropagation()
      setSubmitDisabled(true)
    } else {
      setSubmitDisabled(false)
    }
  }


  const handleSubmit = event => {
    event.preventDefault()
    const form = event.currentTarget

      const data = {
        firstname: form.elements.firstname.value,
        lastname: form.elements.lastname.value,
        email: form.elements.email.value,
        phone: form.elements.phone.value,
        message: form.elements.message.value,
        emailTo: mailTo
      }

      const postForm = async () => {
        try {
          setIsSubmitting(true)
          const response = await axios.post(
            `${process.env.CONTACT_FORM_URL}`,
            queryString.stringify(data)
          )
          setStatus({
            success: submitMessage,
            initial: false
          })
        } catch (exception) {
          setIsSubmitting(false)
          setStatus({
            ...status,
            exception: `${exception.response.status}: ${exception.response.statusText}`
          })
        }
      }
      postForm()
  }

  return (
    <section
      className={status && status.success ? successSectionClass : sectionClass}>
      {status && status.initial && (
        <Container className="py-5 bg-purple">
          <Row className={rowClass}>
            <Col md={10} className="mx-auto">
              <Form
                id="contactForm"
                noValidate
                onChange={validate}
                onSubmit={handleSubmit}
                method="post">
                <>
                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="firstname">
                        <Form.Label for="firstname">First Name*</Form.Label>
                        <Form.Control
                          className={formControlClass}
                          name="firstname"
                          type="text"
                          required
                        />
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group controlId="lastname">
                        <Form.Label for="lastname">Last Name*</Form.Label>
                        <Form.Control
                          className={formControlClass}
                          name="lastname"
                          type="text"
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="email">
                        <Form.Label for="email">Email Address*</Form.Label>
                        <Form.Control
                          className={formControlClass}
                          name="email"
                          type="email"
                          required
                        />
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group controlId="phone">
                        <Form.Label for="phone">Phone number</Form.Label>
                        <Form.Control
                          className={formControlClass}
                          name="phone"
                          type="tel"
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group controlId="message">
                        <Form.Label for="message">Your message*</Form.Label>
                        <Form.Control
                          className={formControlClass}
                          name="message"
                          as="textarea"
                          rows={4}
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="d-flex justify-content-between align-items-center">
                    <Col md={6} lg={7}>
                      <div
                        className={`${checkboxGroupClass} form-group form-check`}>
                        <label
                          className={`${checkboxContainerClass} form-check-label text-start`}
                          htmlFor="terms">
                          <input
                            type="checkbox"
                            className={`form-check-input`}
                            id="terms"
                            name="terms"
                            required
                          />
                          <span className={checkmarkClass}/>I agree to the
                          EPT{" "}
                          <Link
                            classes="linkClass"
                            link={privacyLink?.link}
                            title="Privacy Policy"
                          />
                          {/*{" "} and{" "}
                          <Link
                            classes="linkClass"
                            link={conditionsLink?.link}
                            title="Terms"
                          /> */}
                          {"."}
                        </label>
                      </div>
                    </Col>
                    <Col md={6} lg={5}>
                      <Form.Group className="text-sm-end">
                        <Button
                          type="submit"
                          className={sendBtnClass}
                          disabled={submitDisabled}>
                          Submit
                        </Button>
                      </Form.Group>
                    </Col>
                  </Row>
                </>
              </Form>
            </Col>
          </Row>
        </Container>
      )}
      {status && status.success && (
        <Container className={successContainerClass}>
          <div className={successMsgClass}>{status.success}</div>
        </Container>
      )}
      {status && status.exception && (
        <Alert variant="danger" className="my-5">
          <Alert.Heading>{status.exception}</Alert.Heading>
        </Alert>
      )}
    </section>
  )
}

export default ContactForm